@media only screen and (max-width: 600px) {
  .sigCanvas {
    width: 100%;
    height: 126px;
  }
}

@media only screen and (min-width: 600px) {
  .sigCanvas {
    width: 100%;
    height: 126px;
  }
}

@media only screen and (min-width: 768px) {
  .sigCanvas {
    width: 100%;
    height: 196px;
  }
}

@media only screen and (min-width: 992px) {
  .sigCanvas {
    width: 100%;
    height: 196px;
  }
}

@media only screen and (min-width: 1200px) {
  .sigCanvas {
    width: 100%;
    height: 196px;
  }
}

.cancelBtn:hover {
  background-color: rgba(51, 51, 51, 0.1);
}
