.popout {
    position: absolute;
    bottom: 3rem;
    right: 3rem;
}
.popout .btn {
    display: inline-block;
    position: relative;
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 32px;
    z-index: 999;
    background: #eee;
    color: #00A3DD;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
    transition:0.2s opacity ease-in-out;
    -webkit-transition:0.2s opacity ease-in-out;
}
.popout .btn.active {
    visibility: hidden;
    opacity: 0;
}
.popout .btn:after {
    display: block;
    position: absolute;
    top: 0;
    content: "";
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: #fff;
    z-index: -2;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    transition: transform 0.2s, opacity 0.2s;
}
.popout .btn:active:after {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
}
.popout .panel {
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 340px;
    text-align: left;
    overflow: hidden;
    visibility: hidden;
    transform: scale(0);
    transform-origin: 100% 100%;
    transition: all 0.2s;
    opacity: 0;
}
.popout .panel.active {
    height: auto;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: transform 0.2s, visibility 0s 0s, opacity 0.2s;
}
.popout .panel-header {
    padding: 20px;
    font-size: 15px;
    background-color: #1D70A2;
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.popout .panel-body {
    padding: 10px 20px;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 11px;
    color: #aaa;
}

@media only screen and (min-width: 2100px) {
    .chatBotForLargerScreens {
      width: 500px;
      height: 700px;
    }
  }
