.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: black;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.63);
  border-radius: 15px;
}

.swiper-button-prev, .swiper-button-next {
  background-position: center;
  color: #ff002b !important;
  padding: 30px;
  scale: 0.8;
  border-radius: 80%;
}
/* .swiper-button-next:hover {
  background-color: #ff002b;
  color: white !important;
  transition: all;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
}

.swiper-button-prev:hover {
  background-color: #ff002b;
  color: white !important;
  transition: all;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
} */

::-moz-selection { /* Code for Firefox */
  color: white;
  background: #FF002B;
}

::selection {
  color: white;
  background: #FF002B;
}
