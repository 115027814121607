.test {
  font-size: xx-large;
  text-align: center;
  color: dimgrey;
  font-weight: bold;
}

.sup-desc {
  font-size: medium;
  text-align: center;
  margin-top: 15px;
}

.registerContainer {
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../images/bg.png');
  background-color: black;
}

#showMe {
  animation: cssAnimation 0s 0.8s forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}

#elementToAnimate {
  animation: yourAnimation 0.5s ease 0s normal none;
}

@keyframes yourAnimation {
  0.0% {
    transform-origin: bottom left;
    transform: rotate(0) translate(800px, 0px);
    right: 100%;
  }
}

#bottomNavigation {
  animation: navBottom 0.5s ease 0s normal none;
}

@keyframes navBottom {
  0.0% {
    transform-origin: bottom left;
    transform: rotate(0) translate(0px, 30px);
    right: 100%;
  }
}

.animated {
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
