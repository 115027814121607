.mortgage {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.button {
  background-color: transparent;
  text-decoration: none;
  border-radius: 100px;
  height: 33px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 32px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.button:hover {
  max-width: 100%;
  background-color: white;
  color: black;
  
}

.icon {
  font-family: 'Font Awesome 5 Free';
  display: flex;
  align-items: center;
  margin-right: 30px !important;
}
.text {
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
}
