.advertiser {
    background: #f8f8f8;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .backButton {
  
    text-decoration: none;
  
    height: 32px;
    display: inline-flex;
    align-items: center;
    overflow:hidden;
    width: auto;
    max-width: 32px; 
    -webkit-transition: max-width 0.5s;
    transition: max-width 0.5s;
  }
  
  .backButton:hover {
    max-width: 300px;
  }
  
  .backIcon {
    font-size: 16px;
    margin-right: 10px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
  }
  
  .backText {
    white-space: nowrap;
    padding-right: 15px;
  }
  
  .button {
    background-color: transparent;
    text-decoration: none;
    border-radius: 100px;
    height: 33px;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    width: auto;
    max-width: 32px;
    -webkit-transition: max-width 0.5s;
    transition: max-width 0.5s;
  }
  
  .button:hover {
    max-width: 300px;
    background-color: rgb(245, 236, 236);
    color: black;
  }
  
  .icon {
    font-family: 'Font Awesome 5 Free';
    font-size: 16px;
    margin-right: 55px;
    display: flex;
    align-items: center;
    margin-left: -5px;
  }
  
  .text {
    white-space: nowrap;
    padding-right: 15px;
    font-size: 12px;
    line-height: 14px;
  }
  
  
  