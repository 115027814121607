.label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid black;
}

.label:hover input ~ .checkmark {
  background-color: #f5f5f5;
}

.label input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid black;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.label input:checked ~ .checkmark:after {
  display: block;
}

.label .checkmark:after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: black;
}

/* Customize the label (the container) */
.labels {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.labels input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checks {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.labels:hover input ~ .checks {
  background-color: #f5f5f5;
}

/* When the checkbox is checked, add a blue background */
.labels input:checked ~ .checks {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checks:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.labels input:checked ~ .checks:after {
  display: block;
}

/* Style the checkmark/indicator */
.labels .checks:after {
  left: 4px;
  top: 4px;
  width: 15px;
  height: 15px;
  border: solid #000;
  border-width: 3px 3px 3px 3px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(deg);
  transform: rotate(0deg);
  background: #000;
}

.backButton {
  text-decoration: none;
  height: 32px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 32px; 
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.backButton:hover {
  max-width: 300px;
}

.backIcon {
  font-size: 16px;
  margin-right: 10px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
}

.backText {
  white-space: nowrap;
  padding-right: 15px;
}
