@media only screen and (max-width: 600px) {
    .nav-bar {
      width: 90%;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .nav-bar {
      width: 50%;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .nav-bar {
      width: 45%;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .nav-bar {
      width: 30%;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .nav-bar {
      width: 21.4%;
    }
  }