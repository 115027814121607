.topContainer {
  width: 100vw;
  color: white;
}
.logo {
  position: absolute;
  top: 1.49%;
  bottom: 97.88%;
  display: flex;
  justify-content: center;
  width: 100%;
}
.moto {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 34px;
  position: absolute;
  top: 298px;
  left: 65%;
}
.underline {
  border: 1px solid #ff002b;
  width: 260px;
}
.tabs {
  position: absolute;
  top: 454px;
  left: 289px;
}
.inputSearch {
  position: absolute;
  top: 500;
}
.expandButton {
  background-color: #353535;
  border-radius: 4px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.expandButton:hover span {
  display: none;
}
.expandButton:hover:before {
  content: 'More info ';
  font-size: 11px;
  padding-right: 5px;
}

.test {
  box-shadow: inset 0 -3px 0 -1px #e3e3e3;
  transition: box-shadow 0.4s ease-in-out;
  color: black;
  line-height: 20px;
}
.test:hover {
  box-shadow: inset 0 -550px 0 -100px #c71f3b;
}
.text {
  font-size: 9px;
  line-height: 10.8px;
  text-align: justify;
}

.test:hover .text {
  opacity: 1;
}

.loginButton {
  text-decoration: none;
  height: 50px;
  display: inline-flex;
  align-items: center;
  overflow:hidden;
  width: auto;
  max-width: 32px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.loginButton:hover {
  max-width: 300px;
  height: 50px;
}

.loginIcon {
  font-size: 16px;
  margin-right: 1px;
  margin-left: -15px;
  display: flex;
  align-items: center;
}

.loginText {
  white-space: nowrap;
  padding-right: 15px;
  padding-left: 19px;
}

.backButtonAtTrending {
  text-decoration: none;
  height: 32px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 32px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.backButtonAtTrending:hover {
  max-width: 300px;
}

.backIconAtTrending {
  font-size: 16px;
  margin-right: 10px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
}

.backTextAtTrending {
  white-space: nowrap;
  padding-right: 15px;
}


@media only screen and (max-width: 600px) {
  .data-list-input {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .data-list-input {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .data-list-input {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .data-list-input {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .data-list-input {
    width: 100%;
  }
}

react-datalist-input__container > * {
  box-sizing: border-box;
}

.react-datalist-input__container {
  width: 100%;
  position: relative;
  --shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --gray: rgb(200, 200, 200);
  --padding: 5px;
  --border-radius: 5px;
  --border: 1px solid var(--gray);
}

.react-datalist-input__textbox {
  width: 100%;
  height: 48px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  padding: var(--padding);
  border: var(--border);
  border-radius: var(--border-radius);
  color: #353535;
  outline: none;
}

.react-datalist-input__listbox {
  width: 100%;
  position: absolute;
  margin: 4px 0 0 0;
  padding: 0;
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: white;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  box-shadow: var(--shadow);
  z-index: 20;
  max-height: 210px;
  overflow-y: hidden;
}

.react-datalist-input__listbox-option {
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: var(--padding);
  border-bottom: 1px solid #E3E3E3;
}

.react-datalist-input__listbox-option:focus {
  background-color: var(--gray);
}

.react-datalist-input__listbox-option:hover {
  background-color: #eaeaea;
}
