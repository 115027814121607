.advertiser {
  background: #f8f8f8;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.backButton {
  text-decoration: none;

  height: 32px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 32px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.backButton:hover {
  max-width: 300px;
}

.backIcon {
  font-size: 16px;
  margin-right: 10px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
}

.backText {
  white-space: nowrap;
  padding-right: 15px;
}

.button {
  background-color: transparent;
  text-decoration: none;
  border-radius: 100px;
  height: 33px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 32px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.button:hover {
  max-width: 300px;
  background-color: rgb(245, 236, 236);
  color: black;
}

.icon {
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
  margin-right: 55px;
  display: flex;
  align-items: center;
  margin-left: -5px;
}

.text {
  white-space: nowrap;
  padding-right: 15px;
  font-size: 12px;
  line-height: 14px;
}

.side-content {
  margin-top: 0px !important;
}

.map-container {
  height: 60vh;
  width: 100%;
}

.propertyCard {
  transition-timing-function: ease;
  box-shadow: rgba(0, 0, 0, 0.32) 0px 1px 3px;
  transition-duration: 70ms;
  transition-delay: 0ms;
}

.propertyCard:hover {
  box-shadow: rgba(0, 0, 0, 0.32) 0px 2px 8px;
}

.prop-auction-images {
  margin: 0px;
}

.bidNextButton {
  text-decoration: none;
  height: 50px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 50px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.bidNextButton:hover {
  max-width: 300px;
}

.bidNextIcon {
  font-size: 16px;
  margin-right: 14px;
  padding: 0px 2px;
  display: flex;
  align-items: center;
}

.bidNextText {
  white-space: nowrap;
  padding-right: 15px;
}

.swiper-pagination-bullet-active {
  background-color: red !important;
  opacity: 1 !important;
}