.swiper-service {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide-services {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

/* .swiper-slide img {
    max-height: 180px;
    max-width: 180px;
    height: 180px;
    width: 180px;
} */
