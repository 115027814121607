.ribbon{
    font-size:20px;
    position:absolute;
    display:inline-block;
    margin-top: 0em;
    margin-left: -0.2em;
    margin-right: 0.1em;
    text-align:center;
    border-bottom-right-radius: 5%;
  }
  .text{
    display:inline-block;
    padding:0.4em 1em;
    min-width:2em;
    line-height:1.2em;
    background: #353535;
    position:relative;
    color: white;
  }
  .ribbon:after,.ribbon:before,
  .text:before,.text:after,
  .bold:before{
    content:'';
    position:absolute;
    border-style:solid;
  }
  .ribbon:before{
    top:0.3em; left:0.2em;
    width:100%; height:100%;
    border:none;
    background:#353535;
    z-index:-2;
  }
  .text:before{
    bottom:100%; left:0;
    border-width: .5em .7em 0 0;
    border-color: transparent #353535 transparent transparent;
  }
  .ribbon:after, .bold:before{
    top:0.5em;right:-2em;
    border-width: 1.1em 1em 1.1em 3em;
    border-color: #353535 transparent #353535 #353535;
    z-index:-1;
  }
  .bold:before{
    border-color: #FF002B transparent #353535 #353535;
    top:0.7em;
    right:-2.3em;
  }

  .seeBtn {
    text-decoration: none;
    height: 40px;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    width: auto;
    max-width: 40px; 
    -webkit-transition: max-width 0.5s;
    transition: max-width 0.5s;
  }
  
  .seeBtn:hover {
    max-width: 200px;
  }
  
  .seeInfo {
    font-size: 16px;
    margin-left: 3px;
    margin-right: 5px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
  }
  
  .moreInfo {
    white-space: nowrap;
    padding-right: 10px;
  }
  